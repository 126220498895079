<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 올해점검대상 -->
          <c-select
            :comboItems="targetItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="targetYn"
            label="LBL0003647"
            v-model="searchParam.targetYn"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검년도 -->
          <c-datepicker
            required
            label="LBL0003648"
            name="year"
            type="year"
            default="today"
            v-model="searchParam.year">
          </c-datepicker>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 설비유형 -->
          <c-equip-class
            :editable="editable"
            type="search"
            label="LBL0000633"
            name="equipmentTypeCd"
            v-model="searchParam.equipmentTypeCd">
          </c-equip-class>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 설비 -->
          <c-equip
            label="LBLEQUIP"
            type="search"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검유형 -->
          <c-select
            type="search"
            codeGroupCd="MDM_CHECK_KIND_CD"
            itemText="codeName"
            itemValue="code"
            name="equipmentCheckKindCd"
            label="LBL0003608"
            v-model="searchParam.equipmentCheckKindCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 법정점검주기 -->
          <c-select
            :editable="editable"
            codeGroupCd="MDM_EQUIP_CYCLE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="inspectionCycleCd"
            label="LBL0003630"
            v-model="searchParam.inspectionCycleCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 자체점검주기 -->
          <c-select
            codeGroupCd="MDM_EQUIP_CYCLE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="selfCycleCd"
            label="LBL0003649"
            v-model="searchParam.selfCycleCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 설비등급 -->
          <c-select
            codeGroupCd="MDM_EQUIP_GRADE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="grade"
            label="LBL0003650"
            v-model="searchParam.grade"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!-- 관련 법규 -->
          <c-multi-select
            :editable="editable"
            codeGroupCd="REGULATION_BILL_API_CD"
            itemText="codeName"
            itemValue="code"
            label="LBL0003610"
            name="relatedLawList"
            maxValues="3"
            v-model="searchParam.relatedLawList">
          </c-multi-select>
        </div>
      </template>
    </c-search-box>
    <!-- 설비등급 및 점검계획표 -->
    <c-table
      ref="table"
      title="LBL0003651"
      tableId="table"
      :columns="grid.columns"
      :checkClickFlag="false"
      :data="grid.data"
      rowKey="equipmentCd"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn label="LBLREG" v-if="editable" icon="add" @btnClicked="onItemClick('A')" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'annual-inspection',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'equipmentTypeCd' },
          { index: 1, colName: 'equipmentTypeCd' },
        ],
        columns: [
          {
            name: 'upEquipmentTypeName',
            field: 'upEquipmentTypeName',
            // 대분류
            label: 'LBL0003626',
            style: 'width:200px', 
            align: 'center',
            fix: true,
            sortable: false,
          },
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            // 중분류
            label: 'LBL0003627',
            style: 'width:170px', 
            align: 'center',
            fix: true,
            sortable: false,
          },
          {
            name: 'equipmentCdName',
            field: 'equipmentCdName',
            // 설비명(관리번호)
            label: 'LBL0003662',
            style: 'width:250px',
            align: 'left',
            sortable: false,
            fix: true,
            type: "link",
          },
          {
            name: 'estimationScore',
            field: 'estimationScore',
            // 평가점수
            label: 'LBL0003663',
            style: 'width:40px', 
            align: 'center',
            fix: true,
            sortable: false,
          },
          {
            name: 'gradeName',
            field: 'gradeName',
            // 위험등급
            label: 'LBL0003664',
            style: 'width:45px', 
            align: 'center',
            fix: true,
            sortable: false,
          },
          
          {
            name: 'plantName',
            field: 'plantName',
            // 사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:80px', 
            sortable: false,
            // rowspan: 5,
          },
          {
            name: 'targetYn',
            field: 'targetYn',
            // 올해법정점검대상
            label: 'LBL0003665',
            align: 'center',
            style: 'width:72px', 
            type: 'tag',
            colorItems: [
              { stepperMstCd: 'Y', stepperMstNm: '대상', colorClass: 'blue' },
              { stepperMstCd: 'N', stepperMstNm: '비대상', colorClass: 'blue-grey-4' },
            ],
            sortable: false,
            // rowspan: 5,
          },
          {
            // 점검일정
            label: 'LBL0003666',
            align: 'center',
            style: 'width:360px', 
            sortable: false,
            child: [
              {
                name: 'mon01',
                field: 'mon01',
                // 1월
                label: 'LBL0003667',
                align: 'center',
                style: 'width:50px', 
                sortable: false,
                type: "link",
              },
              {
                name: 'mon02',
                field: 'mon02',
                // 2월
                label: 'LBL0003668',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon03',
                field: 'mon03',
                // 3월
                label: 'LBL0003669',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon04',
                field: 'mon04',
                // 4월
                label: 'LBL0003670',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon05',
                field: 'mon05',
                // 5월
                label: 'LBL0003671',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon06',
                field: 'mon06',
                // 6월
                label: 'LBL0003672',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon07',
                field: 'mon07',
                // 7월
                label: 'LBL0003673',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon08',
                field: 'mon08',
                // 8월
                label: 'LBL0003674',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon09',
                field: 'mon09',
                // 9월
                label: 'LBL0003675',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon10',
                field: 'mon10',
                // 10월
                label: 'LBL0003676',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon11',
                field: 'mon11',
                // 11월
                label: 'LBL0003677',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
              {
                name: 'mon12',
                field: 'mon12',
                // 12월
                label: 'LBL0003678',
                style: 'width:50px', 
                align: 'center',
                sortable: false,
                type: "link",
              },
            ]
          },
          {
            // 법정점검
            label: 'LBL0003661',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'recentInspectionDate',
                field: 'recentInspectionDate',
                // 최근일
                label: 'LBL0003660',
                style: 'width:80px', 
                align: 'center',
                sortable: false,
              },
              {
                name: 'inspectionRequestDate',
                field: 'inspectionRequestDate',
                // 필요일
                label: 'LBL0003659',
                style: 'width:80px', 
                align: 'center',
                sortable: false,
              },
              {
                name: 'inspectionCycleName',
                field: 'inspectionCycleName',
                // 주기
                label: 'LBL0003658',
                style: 'width:60px', 
                align: 'center',
                sortable: false,
              },
            ]
          },
          {
            // 자체점검
            label: 'LBL0003679',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'recentSelfDate',
                field: 'recentSelfDate',
                // 최근일
                label: 'LBL0003660',
                style: 'width:80px', 
                align: 'center',
                sortable: false,
              },
              {
                name: 'selfRequestDate',
                field: 'selfRequestDate',
                // 필요일
                label: 'LBL0003659',
                style: 'width:80px', 
                align: 'center',
                sortable: false,
              },
              {
                name: 'selfCycleName',
                field: 'selfCycleName',
                // 주기
                label: 'LBL0003658',
                style: 'width:60px', 
                align: 'center',
                sortable: false,
              },
            ]
          },
          {
            // 점검자
            label: 'LBL0003657',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'checkDeptNames',
                field: 'checkDeptNames',
                // 부서
                label: 'LBLDEPT',
                style: 'width:100px', 
                align: 'left',
                sortable: false,
              },
              {
                name: 'checkUserNames',
                field: 'checkUserNames',
                style: 'width:150px', 
                // 점검자
                label: 'LBL0003657',
                align: 'left',
                sortable: false,
              },
            ]
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            // 관련법규
            label: 'LBL0003610',
            align: 'left',
            style: 'width:200px', 
            sortable: false,
          },
          {
            name: 'managementDeptName',
            field: 'managementDeptName',
            // 관리부서
            label: 'LBL0003623',
            align: 'left',
            style: 'width:150px', 
            sortable: false,
          },
          {
            // 정비계획
            label: 'LBL0003656',
            align: 'center',
            sortable: false,
            child: [
              {
                name: 'cmFlagName',
                field: 'cmFlagName',
                style: 'width:80px', 
                // 고장정비
                label: 'LBL0003655',
                align: 'center',
                sortable: false,
              },
              {
                name: 'preventCycleName',
                field: 'preventCycleName',
                style: 'width:100px', 
                // 예방정비
                label: 'LBL0003654',
                align: 'center',
                sortable: false,
              },
            ]
          },
        ],
        data: [],
      },
      // 최근법정, 최근자체 제거
      searchParam: {
        plantCd: '',
        year: '',
        equipmentCheckKindCd: null,
        relatedLawList: [],
        equipmentTypeCd: '',
        equipmentCd: '',
        inspectionCycleCd: null,
        selfCycleCd: null,
        targetYn: null,
      },
      targetItems: [
        { code: 'Y', codeName: '대상' },
        { code: 'N', codeName: '비대상' },
      ],
      editable: true,
      searchUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {      
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sop.min.equipment.annual.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      if (!this.searchParam.year) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 
          // item.equipmentNm + 
          'MSG0001050', // 점검년도를 선택하세요
          type: 'warning', // success / info / warning / error
        });
        return;
      }
     // 전체조회
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.equipmentPopup(row, col, true);
    },
    onItemClick() {
      let selectData = this.$refs['table'].selected;

      let title = '';
      let param = null;
      param = {
        title: "연간점검계획",
        selectData: selectData ? selectData: [],
        equipmentCd: '',
        checkDate:  '',
      };
      title = 'LBL0003652'; // 연간점검계획 상세
      this.popupOptions.target = () =>
      import(`${"./equipmentPlan.vue"}`);
      
      this.popupOptions.title = title;
      this.popupOptions.param = param;
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    equipmentPopup(result, col, flag) {
      let title = '';
      let param = null;
      if (col && col.name === 'equipmentCdName') {
        param = {
          equipmentCd: result ? result.equipmentCd : '',
        };
        title = 'LBL0003642'; // 설비마스터 상세
        this.popupOptions.target = () =>
          import(`${"@/pages/mdm/fim/equipmentDetail.vue"}`);
        this.popupOptions.isFull = true;
      } else {
        param = {
          title: "연간점검계획",
          selectData: [],
          plantCd: result.plantCd,
          equipmentCd: result.equipmentCd,
          checkDate:  flag ? this.searchParam.year + '-' + col.name.substring(3) : '',
        };
        title = this.$comm.getLangLabel('LBL0003653', {
          s1: result.equipmentCdName,
          s2: this.searchParam.year,
          s3: col.name.substring(3),
        });
        this.popupOptions.target = () =>
          import(`${"./equipmentPlan.vue"}`);
      }
      
      this.popupOptions.title = title;
      this.popupOptions.param = param;
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.$refs['table'].selected = [];
      if (type === 'COMPLETE' || type === 'SAVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
  }
};
</script>